import { Path } from '@cedalo/webui/src/helper/Path';
import theme from '@cedalo/webui/src/theme';
import { StreamSheetAppBar } from '@cedalo/webui/src/ui/StreamSheetAppBar';
import StreamSheetNavigation from '@cedalo/webui/src/ui/StreamSheetNavigation';
import CustomTooltip from '@cedalo/webui/src/ui/utils/CustomTooltip';
import StreamSheetBreadCrumbs from '@cedalo/webui/src/ui/utils/StreamSheetBreadCrumbs';
import StreamSheetPaper from '@cedalo/webui/src/ui/utils/StreamSheetPaper';
import SettingsPaper from '@cedalo/webui/src/ui/utils/SettingsPaper';
import AddIcon from '@mui/icons-material/esm/Add';
import { Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { CreateWorkspaceView } from './CreateWorkspaceView';
import { EditWorkspaceView } from './EditWorkspaceView';
import { WorkspaceTableView } from './WorkspaceTableView';
import { useNavigate, useParams } from 'react-router-dom';

const drawerWidth = 60;

const DetailView = ({ workspaceId }) => {
	const navigate = useNavigate();
	if (workspaceId === 'new') {
		return (
			<CreateWorkspaceView
				onSubmit={() => navigate(Path.workspaces())}
				onCancel={() => navigate(Path.workspaces())}
			/>
		);
	}
	return <EditWorkspaceView workspaceId={workspaceId} />;
};

export const WorkspacePage = injectIntl(({ intl }) => {
	const { workspaceId } = useParams();
	const [filterText, setTextFilter] = useState('');
	const navigate = useNavigate();

	document.title = intl.formatMessage({ id: 'TabTitle.Workspaces' }, {});

	return (
		<div style={{ width: 'inherit', height: 'inherit' }}>
			<div>
				<StreamSheetAppBar
					key="appPageBar"
					helpContext="maincomponents/workspaces"
					showFilter
					onFilterChange={(newFilter) => setTextFilter(newFilter.toLowerCase())}
				/>
				<StreamSheetNavigation openPage={navigate} />
			</div>
			<Box
				sx={{
					position: 'relative',
					height: 'calc(100% - 50px)',
					width: `calc(100% - ${drawerWidth}px)`,
					marginLeft: `${drawerWidth}px`,
					overflow: 'hidden',
					// @ts-ignore
					bgcolor: theme.wall.backgroundColor
				}}
			>
				<div
					style={{
						display: 'inline-flex',
						width: 'calc(100% - 15px)',
						justifyContent: 'space-between'
					}}
				>
					<StreamSheetBreadCrumbs path="Home/Workspaces" openPage={navigate} />
					<div
						style={{
							alignItems: 'end',
							display: 'flex',
							minHeight: '10px',
							marginTop: '5px'
						}}
					>
						<div
							style={{
								display: 'flex'
							}}
						>
							<CustomTooltip header="Tooltip.AddWorkspace" message="Tooltip.AddWorkspaceMessage">
								<div>
									<IconButton
										aria-label="Add Workspace"
										onClick={() => navigate(Path.workspace('new'))}
										size="small"
									>
										<AddIcon />
									</IconButton>
								</div>
							</CustomTooltip>
						</div>
					</div>
				</div>
				<StreamSheetPaper data-tour="page-users">
					<Box
						sx={{
							height: 'calc(100%)',
							width: 'calc(100% - 342px)'
						}}
						data-tour="page-user"
					>
						<WorkspaceTableView
							selectedWorkspace={workspaceId}
							onSelectWorkspace={(id) => navigate(Path.workspace(id))}
							filterText={filterText}
							onDeleteWorkspace={(id) => (id === workspaceId ? navigate(Path.workspaces()) : null)}
						/>
					</Box>
					<SettingsPaper
						open
						helpContext="maincomponents/workspaces"
                        title={
                            workspaceId === 'new' ?
                                <FormattedMessage id="Extensions.Admin.Workspace.newWorkspace" defaultMessage="Workspace Setttings" /> :
                                <FormattedMessage id="Extensions.Admin.Workspace.editWorkspace" defaultMessage="Workspace Setttings" />
                        }
					>
						{workspaceId ? (
							<DetailView workspaceId={workspaceId} />
						) : (
							<Box sx={{ p: 3, display: 'flex', justifyContent: 'center' }}>
								<Typography color="text.primary" variant="h5">
									<FormattedMessage
										id="Extensions.Admin.Workspace.noWorkspaceSelected"
										defaultMessage="No workspace selected"
									/>
								</Typography>
							</Box>
						)}
					</SettingsPaper>
				</StreamSheetPaper>
			</Box>
		</div>
	);
});
